@import "../../styles/abstract/variables";
@import "../../styles/abstract/mixins";

.buttonBox {
  width: 100%;
  display: flex;

  @include respond(phone) {
    padding: 0 1.5rem;
  }

  @include respond(phone-x-small) {
    flex-direction: column-reverse;
    align-items: center;
    padding: 0 5vw;
  }

  & > * {
    flex-grow: 1;

    @include respond(phone-x-small) {
      &[class] {
        min-width: unset;
        width: 85vw;
      }
    }
  }
}
