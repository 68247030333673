.button[class] {
  font-size: var(--font-size-medium);
  border-radius: 0;
  border: 1px solid;
  transition: color 0.5s, background-color 0.5s;

  & svg[class] {
    font-size: var(--font-size-large);
    margin-right: 0.5rem;
  }
}
