$keywords-editor-max-width: 36rem;
$keywords-editor-max-height: 50rem;

.container {
  --color-orange-darkest: #413121;
  --color-orange-dark: #785a3c;
  --color-orange: #ef9842;
  --color-orange-light: #f0b478;

  max-width: $keywords-editor-max-width;
}

.titleSection {
  display: flex;
  align-items: center;
  padding-bottom: 2rem;
}

.title {
  font-size: var(--font-size-default);
  color: var(--color-orange-darkest);
  padding-right: 1.5rem;
  font-weight: bold;
}

.chipSection {
  display: flex;
  overflow: scroll;
  max-height: $keywords-editor-max-height;
  position: relative;

  & > * {
    flex: 1;
  }
}

.errorMessage {
  color: red;
}
