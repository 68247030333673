.button[class] {
  color: var(--color-primary-light-3);
  font-size: var(--font-size-medium);
  border-radius: 0;

  display: flex;
  align-items: center;
  justify-content: center;

  padding: 1rem 1.5rem;
  border: 1px solid;

  transition: color 0.5s, background-color 0.5s;

  &:hover {
    color: var(--color-primary);
    border: 1px solid;
  }

  & svg[class] {
    font-size: var(--font-size-large);
    margin-right: 0.5rem;
  }
}
