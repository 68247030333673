@import "../../styles/abstract/variables";
@import "../../styles/abstract/mixins";

.container {
  padding: 2rem;
  min-width: 30rem;

  @include respond(phone) {
    padding: 5vw 0;
  }

  @include respond(phone-x-small) {
    min-width: unset;
  }
}

.title {
  margin: 0;
  padding: 3.5rem 2rem 3rem 2rem;
  color: var(--color-grey-dark-2);
  font-size: var(--font-size-default);
  font-weight: bold;
  display: block;
  text-align: center;

  @include respond(phone) {
    padding: 5vw 0;

    &:first-child {
      padding-top: 7.5vw;
    }
  }

  @include respond(phone) {
    padding: 2.5rem 0;

    &:first-child {
      padding-top: 3rem;
    }
  }
}

.errorMessage {
  color: var(--color-secondary);
  text-align: center;
  margin-top: 2rem;
}
