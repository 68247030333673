/* Breakpoints */
$bp-phone-small: 25em; //400px
$bp-phone: 37.5em; //600px
$bp-tabPort: 56.25em; //900px
$bp-tabLand: 75em; //1200px
$bp-bigDesktop: 112.5em; //1800

:root {
  /* box-shadow */
  --box-shadow-default: 0px 1px 1px 1px #00000017;
  --box-shadow-node: -0.3rem 0.7rem 0.5rem hsla(0, 0%, 50.6%, 0.16);
}
