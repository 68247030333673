// MEDIA QUERY MANAGER
/*
0 - 600px:      Phone
600 - 900px:    Tablet portrait
900 - 1200px:   Tablet landscape
[1200 - 1800] is where our normal styles apply
1800px + :      Big desktop

$breakpoint arguement choices:
- phone
- tab-port
- tab-land
- big-desktop

*/

@mixin respond($breakpoint) {
  @if $breakpoint==phone-small {
    @media only screen and (max-width: $bp-phone-small) {
      @content;
    }
  }
  @if $breakpoint==phone {
    @media only screen and (max-width: $bp-phone) {
      @content;
    }
  }

  @if $breakpoint==tab-port {
    @media only screen and (max-width: $bp-tabPort) {
      @content;
    }
  }

  @if $breakpoint==tab-land {
    @media only screen and (max-width: $bp-tabLand) {
      @content;
    }
  }

  @if $breakpoint==big-desktop {
    @media only screen and (min-width: $bp-bigDesktop) {
      @content;
    }
  }
}

@mixin sectionTitle {
  display: flex;
  align-items: center;
  color: var(--color-grey-dark-2);
  padding: 1rem;
  margin-bottom: 2.5rem;
}

@mixin sectionTitleIcon {
  width: 3rem;
  height: 3rem;
  margin: 0 1rem;
}

@mixin mainContentStyle {
  max-width: 100rem;
  margin: 0 auto;
  min-height: 90vh;
  padding: 7rem 3rem 10rem 3rem;
}
