.root {
  display: flex;
  align-items: center;
  background-color: var(--color-orange-light);
  border-radius: 100px;
  padding: 5px;

  & > span {
    font-size: 1rem;
    font-weight: bold;
    padding: 2px 5px 2px 8px;
    color: var(--color-orange-darkest);
    cursor: default;
  }
}

.icon {
  color: var(--color-orange-dark);
  cursor: pointer;
}
