.modal {
  display: flex;
  align-items: center;
  justify-content: center;
}

.closeIcon {
  color: var(--color-grey-light-4);
  position: absolute;
  top: 1.5rem;
  right: 2rem;
  cursor: pointer;

  &:hover {
    color: var(--color-grey-dark-2);
  }
}
