$animation-duration: 4s;

.wrapper {
  padding: 0 5px;
  & > * {
    font-size: 20px;
    color: currentColor;
  }

  & > :nth-of-type(1) {
    animation: animate1 4s linear infinite;
  }
  & > :nth-of-type(2) {
    animation: animate2 4s linear infinite;
  }
  & > :nth-of-type(3) {
    animation: animate3 4s linear infinite;
  }
}

@keyframes animate1 {
  from {
    opacity: 0;
  }
  10% {
    opacity: 0;
  }
  15% {
    opacity: 1;
  }
  90% {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

@keyframes animate2 {
  from {
    opacity: 0;
  }
  35% {
    opacity: 0;
  }
  40% {
    opacity: 1;
  }
  90% {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

@keyframes animate3 {
  from {
    opacity: 0;
  }
  60% {
    opacity: 0;
  }
  65% {
    opacity: 1;
  }
  90% {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}
