.container {
  padding: 2rem;
  min-width: 30rem;
}

.spinnerWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;

  padding-top: 4rem;
}

.text {
  margin: 0;
  padding: 3.5rem 2rem 3rem 2rem;
  color: var(--color-grey-dark-2);
  font-size: var(--font-size-default);
  font-weight: bold;
  display: block;
  text-align: center;
}
