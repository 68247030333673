$button-width: 3rem;
$list-padding-bottom: 0.7rem;
$row-padding-left: 2rem;
$row-padding-verticle: 0.15rem;
$wrapper-padding: 0.7rem 1rem;

.checkBoxTreeWrapper {
  padding: $wrapper-padding;

  & > div > ol {
    padding-left: 0;
  }
  ol {
    padding-left: $row-padding-left;
    padding-bottom: $list-padding-bottom;
    animation: fadeIn 0.3s;

    // 選択肢の一列
    li > span {
      display: flex;
      align-items: center;

      // 開くことのない選択肢の左端のスペース (本来はiconがくる)
      & > span:first-child {
        width: $button-width;
      }

      // 閉じたり開いたりする icon を子にもつ button
      & > button {
        width: $button-width;

        border: none;
        background-color: inherit;
        outline: none;
        color: var(--color-grey-dark-2);

        cursor: pointer;
      }
    }

    // checkbox と 選択肢 のラッパー
    label {
      display: flex;
      align-items: center;
      padding: $row-padding-verticle 0;

      font-size: calc(var(--font-size-large) * 0.9);
      color: var(--color-grey-dark-1);

      cursor: pointer;

      // 内部に checkbox icon をもつ span
      & > span {
        display: flex;
        align-items: center;
        margin-right: 0.75rem;
      }
    }

    // checkbox input element
    input {
      width: 0;
      height: 0;
      opacity: 0;
    }
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
