.animate {
  animation: animate 0.5s cubic-bezier(0.51, -0.02, 0.07, 1.46);
}

@keyframes animate {
  from {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-40%);
  }
  to {
    transform: translateY(0%);
  }
}
